(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"), require("popper.js"));
	else if(typeof define === 'function' && define.amd)
		define(["jquery", "popper.js"], factory);
	else if(typeof exports === 'object')
		exports["materialstyle"] = factory(require("jquery"), require("popper.js"));
	else
		root["materialstyle"] = factory(root["jQuery"], root["Popper"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 